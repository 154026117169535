var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-tip-jar",
      class: ((_obj = {}), (_obj["game-tip-jar--visible"] = _vm.visible), _obj),
    },
    [
      _c("div", { staticClass: "game-tip-jar__inner" }, [
        _c(
          "div",
          {
            staticClass: "tip-jar__payment tip-jar__payment--paypal paypal",
            on: { click: _vm.openPaypalWindow },
          },
          [
            _c("div", { staticClass: "tip-jar__logo-wrapper" }, [
              _c("img", {
                staticClass: "tip-jar__logo",
                attrs: { src: require("@/assets/paypal.svg") },
              }),
            ]),
          ]
        ),
        _c("div", { staticClass: "game-tip-jar__stripe" }, [
          _vm._v("Tip Your Host"),
        ]),
        _c(
          "div",
          {
            staticClass: "tip-jar__payment tip-jar__payment--venmo venmo",
            on: { click: _vm.showVenmoCode },
          },
          [
            _c("div", { staticClass: "tip-jar__logo-wrapper" }, [
              _c("img", {
                staticClass: "tip-jar__logo",
                attrs: { src: require("@/assets/venmo.svg") },
              }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "game-tip-jar__label" }, [_vm._v("Tip")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }