<template>
  <div class="game-tip-jar" :class="{ [`game-tip-jar--visible`]: visible }">
    <div class="game-tip-jar__inner">
      <div
        class="tip-jar__payment tip-jar__payment--paypal paypal"
        @click="openPaypalWindow"
      >
        <div class="tip-jar__logo-wrapper">
          <img class="tip-jar__logo" :src="require('@/assets/paypal.svg')" />
        </div>
      </div>
      <div class="game-tip-jar__stripe">Tip Your Host</div>
      <div
        class="tip-jar__payment tip-jar__payment--venmo venmo"
        @click="showVenmoCode"
      >
        <div class="tip-jar__logo-wrapper">
          <img class="tip-jar__logo" :src="require('@/assets/venmo.svg')" />
        </div>
      </div>
    </div>
    <div class="game-tip-jar__label">Tip</div>
  </div>
</template>

<script>
export default {
  name: "GameTipJar",
  props: {
    host: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openPaypalWindow() {
      window.open(this.paypalUrl, "Paypal", "height=600,width=600")
    },
    showVenmoCode() {
      this.$bus.$emit("OPEN_VENMO_MODAL", this.venmoCode)
    }
  },
  computed: {
    venmoCode() {
      return this.host?.venmo
    },
    paypalUrl() {
      const { paypal } = this.host

      return paypal ? `//paypal.me/${paypal}` : undefined
    }
  }
}
</script>

<style scoped lang="scss">
.game-tip-jar {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 3;

  &:hover,
  &--visible {
    .game-tip-jar__inner {
      opacity: 1;
      visibility: visible;
    }

    .game-tip-jar__label {
      opacity: 0;
      visibility: hidden;
    }

    .game-tip-jar__stripe {
      animation-name: stripeSlide;
      animation-duration: 1.5s;
      animation-name: stripeSlide;
      animation-fill-mode: forwards;
    }

    .tip-jar__payment--venmo .tip-jar__logo {
      animation-duration: 1.5s;
      animation-name: iconPop;
    }

    .tip-jar__payment--paypal .tip-jar__logo {
      animation-name: iconPop;
      animation-duration: 2s;
    }
  }

  &__stripe {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    color: transparent;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    background: $primary_accent_color;

    transform: translate(-100%, -50%);
    cursor: default;
  }
}

.game-tip-jar__inner {
  position: relative;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(70, 150, 210, 1) 50%
  );
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%) rotate(-45deg);
  cursor: pointer;
}

.game-tip-jar__label {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #4696d2;
  transform: translate(50%, 0) rotate(-45deg);
  transform-origin: bottom center;
  padding: 2px 35px 15px;
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
}

.game-tip-jar__label {
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 13px;
  color: white;
}

.tip-jar__payment {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .tip-jar__logo-wrapper {
    transform: rotate(45deg);
    transform-origin: center center;
  }
}

.tip-jar__payment--venmo {
  padding-bottom: 20%;
  .tip-jar__logo-wrapper {
    width: 20%;
  }
  .tip-jar__logo {
    width: 100%;
    height: auto;
  }
}
.tip-jar__payment--paypal {
  padding-top: 26%;
  .tip-jar__logo-wrapper {
    width: 25%;
  }
  .tip-jar__logo {
    width: 100%;
    height: auto;
  }
}

@keyframes stripeSlide {
  25% {
    transform: translate(-100%, -50%);
  }
  75% {
    transform: translate(0, -50%);
    color: transparent;
  }
  100% {
    transform: translate(0, -50%);
    color: $color-white;
  }
}

@keyframes iconPop {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  77% {
    opacity: 1;
    transform: scale(0);
  }
  85% {
    transform: scale(1.2);
  }
  94% {
    transform: scale(1);
  }
}
</style>
